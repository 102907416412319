import * as React from 'react';
import DefaultPage from '../components/layouts/DefaultPage';

const TeilnahmebedingungenPage = () => {
    return (
        <DefaultPage
            useBackButton={true}
            forceDisplay>
            <div className="grid-10--desktop grid-offset-1--desktop grid-8--tablet grid-2--mobile">
                <h1>Teilnahme&shy;bedingungen</h1>
                <ol>
                    <li>
                        Diese Teilnahmebedingungen gelten ergänzend zu den Angaben im Teilnahmeaufruf (nachfolgend
                        „Beschreibung“).
                    </li>
                    <li>
                        Veranstalter ist die FOCUS Magazin Verlag GmbH, die den Wettbewerb in Zusammenarbeit mit ihren
                        Partnern durchführt. Die Preise für den Wettbewerb werden von der Veranstalterin und ihren gemäß
                        §§ 15ff. AktG verbundenen Unternehmen zur Verfügung gestellt.
                    </li>
                    <li>
                        Als Preis erhält der Gewinner Medialeistungen in Höhe von 1 Million Euro brutto, welche er bei
                        den Tochtergesellschaften der BurdaVerlag GmbH verbrauchen kann. Zusätzlich erhält der Gewinner
                        die Mediaplanungsleistung des Vermarkters Brand Community Network GmbH, um den Gewinn möglichst
                        zielführend investieren zu können.
                    </li>
                    <li>
                        Das Mediabudget ist auf Basis der Mediaplanung der Brand Community Network GmbH bis Ende 2022 zu
                        verbrauchen. Es besteht kein Schieberecht. Nicht genutztes Mediabudget verfällt am Schluss des
                        Jahres 2022.
                    </li>
                    <li>
                        Die Teilnahme ist online durch Registrierung mittels eines Online-Formulars auf der Landingpage
                        der Aktion möglich
                    </li>
                    <li>
                        Teilnahmeschluss ist der 20.05.2022. Die Teilnahme ist nur bis zum angegebenen Teilnahmeschluss
                        möglich. Bis dahin muss eine Teilnahme abgeschlossen sein (einschließlich etwaiger
                        Registrierungsbestätigung bei Online-Registrierung). Maßgeblich ist der Zugang beim
                        Veranstalter.
                    </li>
                    <li>
                        Alle angebotenen Teilnahmewege sind hinsichtlich der Gewinnchancen gleichwertig.
                    </li>
                    <li>
                        Sofern die Teilnahmemöglichkeit für alle oder einzelne Teilnahmewege an weitere Bedingungen
                        (z.B. eine Bestellung oder Einwilligung) geknüpft ist, wird dies in der Wettbewerbsbeschreibung
                        dargestellt. Der spätere Widerruf einer Bestellung oder Einwilligung berührt die Gewinnchance
                        nicht.
                    </li>
                    <li>
                        Teilnehmen dürfen ausschließlich Unternehmen im Sinne von § 14 BGB mit einem Sitz oder
                        Niederlassung in Deutschland. Mehrfachteilnahmen eines Unternehmens sind unzulässig, dies gilt
                        nicht, wenn mehrere gemäß §§ 15ff. AktG konzernverbundene Unternehmen jeweils mit einem eigenen
                        Produkt/einer eigenen Dienstleistung teilnehmen. Klarstellend weist der Veranstalter darauf hin,
                        dass sich die Teilnahmeberechtigung auch auf Einzelkaufleute und Einzelunternehmer im Rahmen
                        ihrer jeweiligen unternehmerischen Tätigkeit bezieht, wenn die Teilnehmer über 18 Jahre alt
                        sind. Verbraucher gemäß § 13 BGB sind in jedem Fall nicht teilnahmeberechtigt.
                    </li>
                    <li>
                        Die Teilnehmer müssen ein innovatives Produkt, ein innovatives Verfahren oder eine innovative
                        Dienstleistung einreichen (nachfolgend Beitrag genannt). Dieser Beitrag muss bereits in den
                        Markt eingeführt sein. Prototypen oder Geschäftsideen vor Marktreife sind nicht
                        teilnahmeberechtigt.
                    </li>
                    <li>
                        Das Produkt bzw. die Dienstleistung muss in Deutschland entwickelt und/oder produziert worden
                        sein und bereits am Markt erhältlich sein.
                    </li>
                    <li>
                        Sofern die Erreichbarkeit des Wettbewerbs aus technischen Gründen eingeschränkt ist, resultieren
                        hieraus keine Ansprüche gegen den Veranstalter oder die Partner.
                    </li>
                    <li>
                        Der Veranstalter und die Partner, sowie an der Durchführung des Wettbewerbs beteiligte
                        Dienstleister, sowie im Sinne der §§ 15 ff. AktG jeweils mit diesen verbundenen Unternehmen sind
                        von der Teilnahme ausgeschlossen. Entsprechendes gilt auch für die jeweiligen Mitarbeiter und
                        deren Angehörige.
                    </li>
                    <li>
                        Die Teilnahme über Gewinnspieleintragungsdienste oder über Gewinnspiel-Robots ist unzulässig.
                    </li>
                    <li>
                        Die Beeinflussung der Chancengleichheit durch technische Manipulation, eine Mehrfachteilnahme,
                        die Übermittlung falscher Angaben oder ein vergleichbar schwerer Verstoß führen zum – ggf.
                        nachträglichen – Ausschluss von der Teilnahme.
                    </li>
                    <li>
                        Der Gewinner wird von einer Jury ermittelt. Die Juryvertreter werden von FOCUS Magazin Verlag
                        GmbH bestimmt.
                    </li>
                    <li>
                        Die Jury entscheidet über den Gewinner mittels eines Punktesystems aus den ihr vorgelegten
                        Einsendungen (Vorauswahl) unter den Gesichtspunkten Innovationsleistung, ggf. prognostizierter
                        wirtschaftlicher Erfolg, sowie Ausstrahlungseffekt auf die deutsche Wirtschaft. Jedes
                        Jurymitglied bewertet anhand von einem Punktesystem von 1 bis 10, wobei 10 die höchste Punktzahl
                        und 1 die niedrigste ist. Es gewinnt der Beitrag mit der in Summe höchsten Punktezahl. Jedes
                        Jurymitglied kann pro Beitrag nur einmal Punkte vergeben. Das jeweilige bewertende Jurymitglied
                        ist in seiner Punktevergabe hinsichtlich der pro Beitrag zu vergebenden Punkte frei. Bei
                        Gleichstand wird eine Stichwahl durchgeführt, bei wiederholtem Gleichstand entscheidet das Los
                        über den Gewinner.
                    </li>
                    <li>
                        FOCUS Magazin Verlag GmbH und Burda Principal Investments GmbH & Co. KG (BPI) nehmen eine
                        Vorauswahl der Einsendungen. Am Ende des Vorauswahlprozesses werden der Jury 20 Einsendungen
                        vorgelegt. Dabei werden FOCUS Magazin Verlag GmbH und BPI offensichtlich inadäquate oder nicht
                        ernst gemeinte Einreichungen aussortieren, bevor die Einreichungen der Jury vorgelegt werden.
                        Die Vorauswahl erfolgt auf Basis derselben Bewertungssystematik, wie unter Nr. 17 erläutert.
                    </li>
                    <li>
                        Der Gewinner wird per E-Mail über den Gewinn benachrichtigt. Sofern noch nicht alle für die
                        Gewinnbereitstellung erforderlichen Daten vorliegen, wird der Gewinner aufgefordert, diese
                        Angaben binnen einer angemessenen Frist an den Veranstalter zu übermitteln. Gehen von einem
                        Gewinner innerhalb der in der Benachrichtigung genannten, angemessenen Frist die angeforderten
                        Angaben nicht ein, so verfällt der Gewinnanspruch und der Gewinn wird erneut vergeben.
                    </li>
                    <li>
                        Eine Barauszahlung, Änderung oder ein Ersatz des Gewinns sowie eine Übertragung des
                        Gewinnanspruchs auf einen Dritten sind nicht möglich.
                    </li>
                    <li>
                        Sachpreise werden vom Partner oder von einem von ihm beauftragten Dritten an den Gewinner
                        kostenfrei versendet. Der Gewinner ist verpflichtet, offensichtliche Mängel des Gewinns
                        innerhalb von 14 Tagen beim jeweiligen Partner zu rügen, wobei es zur Wahrung der Frist genügt,
                        wenn die Mängelrüge rechtzeitig an den Partner absendet wird. Ist die Frist abgelaufen, kann
                        sich der Gewinner nicht mehr auf offensichtliche Mängel berufen. Der Veranstalter ist nicht für
                        Sach- und Rechtsmängel am Gewinn verantwortlich.
                    </li>
                    <li>
                        Der Veranstalter verarbeitet (auch mit Hilfe der von Dienstleistern) die von den Teilnehmern
                        angegebenen personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 b) bzw. f) DSGVO, soweit und
                        solange dies zur Durchführung des Wettbewerbs und zur Gewinnbereitstellung erforderlich ist,
                        sowie zu eigenen und fremden Werbezwecken oder die Teilnehmer anderweitig eingewilligt haben. Im
                        Rahmen der Verarbeitung auf Basis berechtigter Interessen verfolgen wir das Interesse der
                        Werbung. Zwecks Gewinnbereitstellung werden die Kontaktdaten der Teilnehmer ggf. an den
                        jeweiligen, aus der Wettbewerbsbeschreibung ersichtlichen Partner und an mit der Bereitstellung
                        des Preises beauftragte Dritte offengelegt. Weitere Informationen zur Datenverarbeitung finden
                        Sie unter https://www.focus-magazin.de/datenschutz-web.
                    </li>
                    <li>
                        Darüber hinaus räumt der Gewinner dem Veranstalter gegenüber das nicht ausschließliche Recht
                        ein, die von ihnen bei der Veranstalterin im Rahmen des Wettbewerbs eingereichten Beiträge zu
                        Werbe-, Promotion- und Marketingzwecken und zu wissenschaftlichen und redaktionellen Zwecken
                        unentgeltlich, zeitlich, inhaltlich und örtlich uneingeschränkt auf Webseiten, in Printmedien
                        und in Film- und Tonaufzeichnungen des Veranstalters und seiner gemäß §§ 15ff. AktG verbundenen
                        Unternehmen zu nutzen. Nicht gewinnende Teilnehmer räumen diese Rechte ebenfalls ein, sofern
                        diese im Bewerbungsprozess in diese Nutzung gesondert eingewilligt haben.
                    </li>
                    <li>
                        Der jeweilige Teilnehmer bestätigt und gewährleistet gegenüber dem Veranstalter, dass er über
                        sämtliche der in dieser Ziffer 15 genannten Rechte in Bezug auf die von ihm eingereichten
                        Beiträge verfügt und diese dem Veranstalter ohne die Verletzung von Rechten Dritter, gleich
                        welcher Art (z.B. Marken-, Namens-, Urheber-, Wettbewerbs- und Datenschutzrecht,
                        jugendschutzrechtliche Bestimmungen) einräumen darf. Falls der jeweilige Teilnehmer selbst nicht
                        Rechteinhaber bezüglich der eingereichten Beiträge ist, garantiert er, alle erforderlichen
                        Rechte, Lizenzen, Gestattungen, Einwilligungen, Vollmachten und Befugnisse der
                        Beteiligten/Betroffenen/Berechtigten wirksam eingeholt zu haben und dem Veranstalter auf
                        Verlangen jederzeit nachweisen zu können. Für den Fall, dass ein Beitrag gegen die vorgenannten
                        Anforderungen verstößt, stellt der jeweilige Teilnehmer den Veranstalter von jeglicher daraus
                        resultierender Haftung gegenüber Dritten frei und erklärt sich damit einverstanden, dem
                        Veranstalter alle daraus entstehenden Schäden, inklusive den Kosten der Rechtsverteidigung, zu
                        ersetzen.
                    </li>
                    <li>
                        Sofern der Wettbewerb auch über soziale Netzwerke (z.B. Facebook, Instagram) veranstaltet oder
                        beworben wird, gilt: Jeder Teilnehmer stellt den Betreiber des sozialen Netzwerks von jeglicher
                        Haftung frei. Der Wettbewerb steht in keiner Verbindung zu dem jeweiligen Betreiber des sozialen
                        Netzwerks und wird in keiner Weise von diesem gesponsert, unterstützt oder organisiert.
                    </li>
                    <li>
                        Die Haftung des Veranstalters im Zusammenhang mit der Durchführung des Wettbewerbs, gleich aus
                        welchem Rechtsgrund, ist ausgeschlossen. Dies gilt nicht bei Vorsatz oder grober Fahrlässigkeit
                        und/oder bei der Verletzung von Kardinalspflichten. Kardinalspflichten sind wesentliche
                        Vertragspflichten, bei deren Verletzung die Erreichung des Vertragszwecks gefährdet ist und
                        deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf
                        deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. Bei nicht vorsätzlicher und
                        nicht grob fahrlässiger Verletzung von Kardinalspflichten, ist der Umfang auf den Ersatz des
                        typischen und bei Vertragsschluss vorhersehbaren Schadens begrenzt. Die vorstehenden
                        Haftungsbegrenzungen gelten nicht, sofern der Veranstalter zwingend haftet, insbesondere für
                        Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit.
                    </li>
                    <li>
                        Der Rechtsweg ist ausgeschlossen.
                    </li>
                </ol>
            </div>
        </DefaultPage>
    );
};

export default TeilnahmebedingungenPage;
